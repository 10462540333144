<template>
    <Guest>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                <i18n-t keypath="login.reset.title" />
            </h2>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form v-on:submit.prevent="handleSubmit">
                    <TextInput v-model:value="form.email" v-model:errors="errors.email" :label="$t('login.reset.email.title')" :placeholder="$t('login.reset.email.placeholder')" border="enable" />
                    <TextInput v-model:value="form.password" v-model:errors="errors.password" :label="$t('login.reset.password.title')" :placeholder="$t('login.reset.password.placeholder')" password class="mt-4" border="enable" />
                    <TextInput v-model:value="form.password_confirmation" v-model:errors="errors.password_confirmation" :label="$t('login.reset.repeat_password.title')" :placeholder="$t('login.reset.repeat_password.placeholder')" password class="mt-4" border="enable"/>
                </form>
            </div>
            <div class="mt-6">
                <router-link :to="{ name: 'login' }">
                    <span  class="inline-block w-5/12 sm:w-3/12 rounded-md shadow-sm">
                        <button type="button" class="button-transparent w-full">
                            <i18n-t keypath="general.back" />
                        </button>
                    </span>
                </router-link>
                <span @click.prevent="handleSubmit" class="inline-block w-5/12 rounded-md shadow-sm float-right">
                    <button type="button" class="button bg-gradient-to-r from-gradient-orange to-gradient-red w-full p-4 rounded text-white font-medium">
                        <i18n-t keypath="login.reset.submit" />
                    </button>
                </span>
            </div>
        </div>
    </Guest>
</template>

<script>
import Guest from '@/wrappers/Guest';
import TextInput from "@/components/forms/TextInput";
import { resetPassword } from "@/helper/api/PasswordResetHelper";
import messages from "@/helper/store/messageHelper";
import { setLoading } from "@/helper/store/loadingSpinnerHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
    components: { Guest, TextInput },
    data() {
        return {
            form: {
                email: '',
                password: '',
                password_confirmation: '',
                token: '',
            },
            errors: {
                email: [],
                password: [],
                password_confirmation: [],
                token: [],
            }
        }
    },
    methods: {
        handleSubmit(){
            setLoading(true);

            resetPassword(this.form)
                .then(() => {
                    messages.success($t('login.reset.success'));
                    this.$router.push("/login");
                })
                .catch(error => {
                    messages.error($t('login.reset.failure'));
                    this.errors = error.errors;
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    },
    created: function() {
        // add the token from the route to the component data
        this.form.token = this.$route.params.token;
    },
}
</script>